import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { size } from 'lodash';

import {
  AccordionIcon,
  EnRouteIcon,
  EnRouteStoppedIcon,
  PausedIcon,
  PendingReviewIcon,
  ReachedIcon,
  StartedIcon,
  StoppedIcon,
} from 'assets/images/svg-icons';
import Pusher from 'pusher-js';

import { AuthContext } from 'context/auth-context/auth-context';
import { getJobs } from 'services/jobs';
import Loader from 'components/shared/loader';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import JobCard from './job-card';

const OpenJobsContainer = (props) => {
  const {
    showMyJobsStatus,
    loading,
    setLoading,
    setServiceFilters,
    setPMFilters,
    startDate,
    endDate,
    filterParams,
  } = props;
  const [userDetails] = useContext(AuthContext);
  const [totalJobs, setTotalJobs] = useState(0);
  const [openJobs, setOpenJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});

  const getClassName = (job) => {
    switch (job) {
      case 'pending': return 'co-ordinating';
      case 'in_progress': return 'work-in-progress';
      case 'scheduled': return 'scheduled';
      case 'submitted': return 'waiting';
      case 'paused': return 'paused';

      default: return '';
    }
  };

  const accordionButtonHandler = (type) => {
    setSelectedJob((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchingOpenJobs = useCallback((loadingState) => {
    setLoading(loadingState);
  }, [setLoading]);

  const fetchOpenJobs = useCallback((params) => {
    setOpenJobs([]);
    fetchingOpenJobs(true);
    getJobs('open', params).then((res) => {
      fetchingOpenJobs(false);
      setOpenJobs(res?.data);
      const filterData = res?.data?.filters;
      const serviceCategories = Object.keys((filterData?.service_categories || {}))?.map((key) => ({
        value: key, label: filterData?.service_categories[key],
      }));
      setServiceFilters(serviceCategories);
      const propertyManagers = Object?.keys((filterData?.property_managers || {}))?.map((key) => ({
        value: key, label: filterData?.property_managers[key],
      }));
      setPMFilters(propertyManagers);
    }).catch(() => {});
  }, [setOpenJobs, fetchingOpenJobs, setPMFilters, setServiceFilters]);

  const onJobNotesModalCloseHandler = useCallback((type, jobId) => {
    setOpenJobs((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        data: prev[type]?.data?.map((item) => {
          if (item?.job_id === jobId) {
            return {
              ...item,
              has_unread_customer_notes: false,
            };
          }
          return item;
        }),
      },
    }));
  }, []);

  useEffect(() => {
    fetchOpenJobs({
      from_date: startDate,
      to_date: endDate,
      ...filterParams,
      show_my_jobs: showMyJobsStatus ? 1 : 0,
    });
  }, [endDate, fetchOpenJobs, filterParams, showMyJobsStatus, startDate]);

  useEffect(() => {
    if (userDetails?.partner?.partner_key) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      });
      const jobChannel = pusher.subscribe(`open-jobs-${userDetails?.partner?.partner_key}`);
      jobChannel.bind('open-job.updated', (response) => {
        setOpenJobs((prevJobs) => {
          let matched = false;
          let filteredJobs = {};
          if (prevJobs) {
            ['pending', 'submitted', 'scheduled', 'in_progress', 'paused']?.forEach((job) => {
              filteredJobs = {
                ...filteredJobs,
                [job]: {
                  ...prevJobs[job], data: [],
                },
              };
              prevJobs[job]?.data?.forEach((item) => {
                if (item?.tenant_entry_id === response?.tenant_entry_id) {
                  if (job === response?.status) {
                    matched = true;
                    filteredJobs[job].data = [...filteredJobs[job]?.data, response];
                  }
                } else {
                  filteredJobs[job].data = [...filteredJobs[job]?.data, item];
                }
              });
            });
          }
          if (!matched && ['pending', 'submitted', 'scheduled', 'in_progress', 'paused']?.includes(response?.status)) {
            filteredJobs = {
              ...filteredJobs,
              [response?.status]: {
                ...filteredJobs[response?.status],
                data: [
                  ...filteredJobs[response?.status]?.data,
                  response,
                ],
              },
            };
          }
          return { ...prevJobs, ...filteredJobs };
        });
      });
    }
  }, [userDetails?.partner?.partner_key, setOpenJobs]);

  const mapIcon = {
    started: <StartedIcon />,
    enrouted: <EnRouteIcon />,
    'enroute-stopped': <EnRouteStoppedIcon />,
    reached: <ReachedIcon />,
    stopped: <StoppedIcon />,
    'to-be-billed': <PendingReviewIcon />,
    'kick-back-to-pro': <PendingReviewIcon />,
  };

  useEffect(() => {
    let total = 0;
    if (openJobs) {
      ['pending', 'submitted', 'scheduled', 'in_progress', 'paused']?.forEach((type) => {
        total += size(openJobs[type]?.data);
      });
    }
    setTotalJobs(total);
  }, [openJobs, setTotalJobs]);

  return (
    <div className="data-card p-0 data-card-open-jobs">
      <ul className="open-jobs">
        {loading ? <Loader />
          : (
            <React.Fragment>
              {openJobs && ['pending', 'submitted', 'scheduled', 'in_progress', 'paused']?.map((type) => (
                <li key={type} className={getClassName(type)}>
                  <div className="jobs-heading" onClick={() => accordionButtonHandler(type)}>
                    <div className="jobs-heading-icon" />
                    <div className="jobs-heading-data">
                      <div className="jobs-heading-data--inner">
                        <h4>
                          {(openJobs[type]?.data
                              && Math.floor((size(openJobs[type]?.data) / totalJobs) * 100)) || 0 }
                          % (
                          {openJobs && openJobs[type] && openJobs[type]?.data
                              && size(openJobs[type]?.data)}
                          )
                        </h4>
                        <p>
                          {openJobs && openJobs[type] && openJobs[type]?.label}
                        </p>
                      </div>
                      <Link
                        to="#"
                        className={classNames('accordion-btn', { 'accordion-arrow': selectedJob[type] })}
                      >
                        <AccordionIcon />
                      </Link>
                    </div>
                  </div>
                  <div className={classNames('jobs-data-wrap', 'd-lg-block', { 'd-none': !selectedJob[type] })}>
                    { openJobs[type]?.data?.map((job) => (
                      <React.Fragment key={job?.tenant_entry_id}>
                        <JobCard
                          job={job}
                          type={type}
                          mapIcon={mapIcon}
                          onJobNotesModalCloseHandler={onJobNotesModalCloseHandler}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </li>
              ))}
            </React.Fragment>
          )}
      </ul>
    </div>
  );
};

export default OpenJobsContainer;
